.section-breadcrumb {
      position: relative;
}
.breadcrumb-inner {
      background-image: url("/public/products/breadcrumb.png");
      padding: 10rem 0;
      padding-bottom: 6rem;
      background-position: center center;
      background-size: cover;
}
.breadcrumb-inner li {
      display: inline-block;
}

.breadcrumb-overlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--primary-color);
      opacity: 0.9;
      height: 100%;
      width: 100%;
}
.breadcrumb-content li a,
.breadcrumb-content li {
      font-size: var(--font-extraSmall);
      padding: 0 1rem;
      text-transform: uppercase;
      color: var(--yellow-color);
      letter-spacing: 0.3em;
      word-spacing: 0.1em;
}
.breadcrumb-content ul li:nth-child(1)::after {
      content: "";
      top: 12px;
      right: 5px;
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: var(--yellow-color);
      display: block;
}
.beforeList::after {
      content: "";
      top: 12px;
      right: 6px;
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: var(--yellow-color);
      display: block;
}
.breadcrumb-title {
      color: #fff;
      text-transform: uppercase;

      letter-spacing: 0.1em;
}
.section-breadcrumb::before {
      /* content: ""; */
      z-index: 55;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3rem;
      background: linear-gradient(
            to top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.5) 50%,
            /* Intermediate color stop */ rgba(255, 255, 255, 1) 100%
      );
}

@media screen and (max-width: 568px) {
      .breadcrumb-title {
            text-align: center;
      }

      .breadcrumb-inner {
            padding: 8rem 0;
            padding-bottom: 4rem;
      }
}
