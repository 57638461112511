.section-banner {
      background-color: #fff;
      /* padding-bottom: 60px; */
}

.single-banner {
      padding: 5rem 2rem;
      width: 100%;
      height: 100%;
      background-color: #fff;
      text-align: justify;
}

.banner-bg {
      background-image: url("/public/assets/bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
}

.banner-img {
      background-image: url("/public/assets/banner.jpg");
      background-repeat: no-repeat;
      height: 100%;
      background-size: cover;
}

.box-shadow {
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.video__container video,
iframe {
      border-radius: 8px;
      width: 100%;
      height: auto;
}

.row-single {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      column-gap: 1.5rem;
}

.container-marquee {
      margin-top: 30px;
}

@media (max-width: 1200px) {
      .container-marquee {
            max-width: 85%;
            margin-left: 100px;
      }
}

@media (max-width: 992px) {
      .container-marquee {
            max-width: 90%;
            margin-left: 50px;
      }
      .row-single {
            gap: 1rem;
      }
}

@media (max-width: 768px) {
      .container-marquee {
            max-width: 95%;
            margin-left: 20px;
      }
}

@media (max-width: 576px) {
      .container-marquee {
            max-width: 100%;
            margin-left: 0;
            margin-top: 20px;
      }
      .row-single {
            display: flex;
            flex-wrap: nowrap;
      }
}
