.blog-widget:hover .recent-img img {
      transform: scale(1.1);
}
.blog-widget:hover .recent-title,
.blog-widget:hover .meta-date {
      color: var(--primary-color);
}
.recent-img img {
      transition: transform 0.3s ease-out;
}
.meta-date i {
      /* font-size: 19px; */
}
.blog-widget {
      margin-bottom: 2rem;
}
.blog-widget:last-child {
      margin-bottom: 0;
}
.sidebar-recent {
      display: flex;
}
.recent-img {
      overflow: hidden;
      width: 160px;
      height: 96px;
}
.recent-img img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
}
.recent-content {
      width: 100%;
      height: 100%;
      padding-left: 1rem;
}
.recent-title {
      color: #222;
      font-size: 16px;
}
.meta-date {
      color: var(--gray-color);
      font-size: 12px;
}
