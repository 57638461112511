.water-drop1 {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 200px;
}
.water-drop2 {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 200px;
      z-index: 10;
}
