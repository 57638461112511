/* style.css */
.home-values-section {
      padding-top: 5rem;
}

/* Background colors for feature items */
.quality-bg {
      background-color: #13365a;
}

.trust-bg {
      background-color: #2e5d94;
}

.innovation-bg {
      background-color: #0360af;
}

.feature-icon {
      font-size: 55px;
      margin-bottom: 10px;
}

.arrow-icon {
      position: absolute;
      bottom: 10px;
      left: 20px;
      font-size: 25px;
}

.partners-section h4 {
      font-size: 24px;
      font-weight: 600;
      color: #333;
      margin-top: 20px;
}

@media screen and (max-width: 568px) {
      .home-values-section {
            padding-top: 4rem;
      }
}
