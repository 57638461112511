.section-description {
      background-color: #f2f2f2;
      border-radius: 4px;
      padding: 20px;
      border: 1px solid #ddd;
}
.table {
      --bs-table-bg: #f2f2f2;
}
.desc-title {
      color: var(--gray-color);
      font-weight: 500;
}
.desc-info .table {
      --bs-table-color: var(--gray-color);
}
.desc-info .table .w-3 {
      width: 25%;
}
.desc-info .table .w-6 {
      width: 75%;
}

@media screen and (max-width: 568px) {
      .section-description {
            margin-top: 2rem;
      }
}
