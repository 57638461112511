@keyframes heartBeat {
      0% {
            transform: scale(1);
      }
      50% {
            transform: scale(1.1);
      }
      100% {
            transform: scale(1);
      }
}

.inner-home__about--content {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;
}

.inner-home__about--content p {
      text-align: justify;
      color: #555;
      font-weight: 300;
      font-family: "Outfit", sans-serif;
      font-size: 16px;
}

.pipe {
      position: absolute;
      right: 7%;
      top: -0%;
      animation: heartBeat 0.7s infinite;
      z-index: 100;
}

.pipe img {
      width: 110px !important;
}

@media screen and (max-width: 568px) {
      .pipe {
            top: 7%;
      }
}
