.career-form {
      padding: 32px;
      background-color: #f2f2f2;
      border-radius: 4px;
}

.career-form .form-control,
.career-form .form-select {
      padding: 15px 15px;
      background-color: var(--bs-body-bg) !important;
}
