.section-header.sticky {
      position: fixed;
      top: 0;
      z-index: 9999;
      height: auto;
      background-color: #fff;
      transition-delay: 0.2s;
      transition: ease-in-out;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-bottom: 1px solid #ddd;
}
.main-header {
      position: absolute;
      z-index: 555;
      background-position: center right;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
}
.pattern-bg {
      overflow: hidden;
      width: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 90px;
}

.section-header {
      position: absolute;
      top: 0%;
      width: 100%;
      height: 100%;
      transform: translateY(-0%);
      display: flex;
      align-items: center;
}
.header-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
}
.header-lists {
      background-image: url(/public/banners/backgroundheader.png);
      background-position: center right;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
}
.header-lists li {
      display: inline-block;
      list-style: none;
      position: relative;
}
.header-container {
      display: flex;
}
.header-logo img {
      width: 160px;
      height: auto;
}
.header-lists {
      display: flex;
      justify-content: center;
      align-items: center;
}
.header-lists li a {
      display: inline-block;
      padding: 13px 20px;
      vertical-align: middle;
      font-weight: 300;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
}

.header-lists li:hover {
      background-color: rgba(255, 255, 255, 0.3);
}

.header-lists li:hover a {
      color: #fff;
}
.header-lists li:first-child {
      border-radius: 10px 0px 0px 10px;
}
.header-lists li:hover > .dropdown {
      display: block;
      transition: all 0.3s linear;
}
.dropdown {
      display: none;
      transition: all 0.3s linear;
}

.header__contant--info div {
      line-height: 1;
}

.header__contant--info div a {
      margin-bottom: 0px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
}
.header__contact--icon img {
      width: 24px;
}
.header__contant--info p {
      color: #ffffff;
      font-family: "Roboto", Sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin-top: 3px;
      margin-bottom: 0px;
}
@media screen and (max-width: 1200px) {
      .header-lists {
            display: none !important;
      }
}

@media screen and (max-width: 568px) {
      .section-header {
            display: none;
      }
      .header-container {
            padding: 0 10px;
      }

      .pattern-bg {
            display: none;
      }
}
