.blog__img{
      height: 500px;
}
.blog__desc{
      color: #222;
      text-align: justify;

}
.blog__meta{
      color: var(--gray-color);
}
.blog__img img{
      width: 100%;
      height: 100%;
      object-fit: cover;
}
blockquote{
      text-align: justify;
      margin: 0px 0px 0px 50px;
    padding: 0px 0px 0px 20px;
    border-style: solid;
    border-width: 0px 0px 0px 2px;
    border-color: var(--secondary-color);
}

@media screen and (max-width: 568px) {
      .blog__img {
            height: 320px;
      }
}