.single-contact__detail {
      display: flex;
      align-items: center;
}
.contact-icon i {
      font-size: 24px;
      line-height: 1.7rem;
      color: #fff;
}
.contact-icon {
      padding: 0.3rem;
      width: 36px;
      height: 36px;
      background-color: var(--primary-color);
      text-align: center;
      border-radius: 5px;
}
.contact-info {
      margin-left: 0.8rem;
      width: 100%;
      height: 100%;
}

.contact-content .font-small {
      color: #222;
}
.contact-title {
      font-weight: 500;
}
.google-map-embed iframe {
      width: 100%;
      height: 500px;
}

.contact-btn .btn {
      font-family: var(--font-base);
      font-weight: 500;
      width: 100%;
      border: 0;
      height: 36px;
      background-color: var(--primary-color);
      transition: background-color ease 0.4s;
}

.contact-btn .spinner-border {
      border-width: 1px;
      width: 1rem;
      height: 1rem;
}

.contact-btn .btn:hover {
      background-color: var(--yellow-color);
      color: #222;
      border-color: var(--yellow-color);
}
.form-control:focus {
      background-color: unset;
      box-shadow: unset;
      border-color: var(--primary-color);
}
.single-contact__detail {
      transition: background-color ease 0.4s;
}
.single-contact__detail:hover .contact-icon {
      background-color: var(--yellow-color);
      color: var(--yellow-color);
      transition: background-color ease 0.4s;
}
.single-contact__detail:hover .contact-icon i {
      color: #222;
}
.single-contact__detail:hover .contact-title {
      color: var(--secondary-color);
}

@media screen and (max-width: 568px) {
      .single-contact__detail {
            margin-bottom: 10px;
      }
}
