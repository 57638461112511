.accordion-item {
	border: 0;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
}

.accordion-button:focus {
	border-color: transparent;
	box-shadow: none;
}
.accordion-button:not(.collapsed) {
	background-color: transparent;
}

.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type .accordion-button.collapsed {
	border-radius: 0;
}
.accordion-button {
	padding-left: 0px;
}

/* faq side */
.faq-side {
    background-image: url('/public/faq.png');
    background-size: cover;
    background-position: center;
    border-radius: 1px;
    padding: 20px;
    color: white;
    width: 100%;
    height: 380px;
    object-fit: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
}

.faq-side::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgb(56, 55, 55);
    opacity: 0.8;
    width: 100%;
    height: 100%;
}

.faq-content {
    position: relative; 
    z-index: 1; 
    text-align: left;
    max-width: 600px; 
    padding: 20px;
}

.faq-content h2 {
    text-transform: uppercase;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.2rem;
    line-height: 33px;
    margin-bottom: 30px;
}

.faq-content p {
    font-size: 16px;
  
    margin-bottom: 30px;
}

  