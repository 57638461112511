.app__footer {
      position: relative;
      background-color: #e3eff6;
}
.footer__background {
      position: absolute;
      bottom: 30px;
      /* z-index: -1; */
}
.footer-ul a {
      display: block;
      padding: 0.4rem 0;
      color: #222;
}
.footer-ul a:hover {
      color: var(--primary-color);
}
.footer-title {
      color: var(--primary-color);
}
.footer-input svg {
      fill: var(--secondary-color);
      width: 15px;
      height: 15px;
}
.app__logo {
      margin-right: 3rem;
}
.app__footer .footer__container {
      padding: 60px 0px;
}
.app__logo img {
      width: 160px;
}
.footer-input input {
      border: 0;
      font-size: 14px;
      outline: 0;
      background-color: #fff;
      border-bottom: 1px solid #cccccc;
      border-radius: 0;
      font-family: var(--font-base);
}
.footer-input {
      display: flex;
      align-items: center;
}
.footer-icons {
      padding: 0.4rem 0px;
}
.footer-icons svg {
      width: 18px;
      height: 18px;
      fill: var(--secondary-color);
      margin-right: 18px;
}
.footer-input svg {
      width: 20px;
      height: 20px;
      fill: var(--secondary-color);
      margin-right: 18px;
      transition: all 0.3s ease-in-out;
}

.footer-input svg:hover {
      fill: var(--primary-color);
}

.footer-input .spinner-border {
      border-width: 1px;
      color: var(--secondary-color);
      width: 1rem;
      height: 1rem;
      margin-right: 18px;
}

.footer-input button {
      border: 0;
      width: 50px;
      height: 50px;
      background-color: transparent;
}

.footer-input button:focus,
.footer-input button:hover {
      background-color: transparent;
}

.footer-input input:focus,
.form-select:focus {
      box-shadow: none;
}
.form-control {
      padding: 15px 0px;
      background-color: transparent !important;
}
.footer-copyright {
      background-color: var(--primary-color);
}
.footer-copyright a {
      color: #f7bf23;
}
.copyright-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.7rem 0;
      color: #fff;
      font-size: 14px;
      font-weight: 300;
}
.copyright-inner span {
      color: #f7bf23;
}

.footer__container h3 {
      font-size: 18px;
}
.footer__container ul li {
      font-size: 15px;
}

.footer__container p {
      font-size: 15px;
}
.water-drop1 {
      top: -10%;
}
.water-drop2 {
      bottom: 20%;
}

@media screen and (max-width: 1200px) {
      .footer__background {
            bottom: 43px;
      }
}

@media screen and (max-width: 568px) {
      .app__logo {
            margin: 0;
      }

      .footer__background {
            bottom: 62px;
      }

      .copyright-inner {
            flex-direction: column;
            z-index: 99999;
            position: relative;
      }
}
