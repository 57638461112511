.dealer-branch-h2{
    margin-bottom: 20px !important;
}
.branch-dealer-cards{
    margin-bottom: 25px;
}
.branch-dealer-cards-padding{
    padding: 20px !important;
    box-shadow: none !important;
}
.branch-icons{
    margin-right: 10px;
}
.branch-dealer-cards-padding:hover{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}