.store__container {
      position: sticky;
      top: 100px;
}

.feature__product-title {
      position: relative;
      text-transform: uppercase;
      font-size: 24px;
      letter-spacing: 0.1rem;
      line-height: 38px;
      color: var(--primary-color);
      text-align: center;
}
.feature__product-title::before,
.feature__product-title::after {
      position: absolute;
      content: "";
      width: 38%;
      border-bottom: 2px solid var(--primary-color);
      height: 21px;
}
.feature__product-title::before {
      right: 0;
}
.feature__product-title::after {
      left: 0;
}

.search__bar {
      border: 1px solid rgb(238, 238, 238);
      border-radius: 5rem;
      padding: 5px;
}
.search__bar input {
      padding: 0px;
      border: 0;
      padding: 0 15px;
}
.search__bar input:focus {
      color: var(--bs-body-color);
      background-color: transparent;
      border-color: var(--primary-color);
      outline: 0px;
      box-shadow: none;
}
.search__bar button {
      height: 38px;
      width: 42px;
      background: var(--primary-color);
}
.store__widget-title {
      font-size: 18px;
      color: var(--secondary-color);
}
.store__widget .form-check {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 0;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-left: 0;
      margin-bottom: 10px;
}
.store__widget .form-check-input {
      border: 2px solid gray;
}
.store__widget .form-check-input:checked {
      background-color: var(--primary-color);
}
.store__widget .form-check-input:focus {
      box-shadow: none;
      outline: 0;
}
.store__widget .accordion .accordion-item {
      border: 0;
}
.store__widget .accordion .accordion-item .accordion-button,
.store__widget .accordion .accordion-item .accordion-button.collapsed {
      padding: 10px 0;
      font-size: 16px;
}
.store__widget .accordion .accordion-item .accordion-button:hover {
      color: var(--secondary-color);
}
.store__widget .accordion-button:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
}
.store__widget .accordion-button:focus {
      box-shadow: none;
}
.store__widget .accordion-body label.form-check-label {
      font-size: 15px;
}
.store__widget .accordion-body .form-check {
      border-bottom: 1px solid rgb(222, 226, 230);
      padding-bottom: 10px;
}
.price-input-container {
      width: 100%;
}

.price-input .price-field {
      display: flex;
      margin-bottom: 22px;
}

.price-field span {
      margin-right: 10px;
      margin-top: 6px;
      font-size: 17px;
}

.price-field input {
      flex: 1;
      height: 35px;
      font-size: 15px;
      font-family: "DM Sans", sans-serif;
      border-radius: 9px;
      text-align: center;
      border: 0px;
      background: var(--bg-color) !important;
}

.price-input {
      width: 100%;
      font-size: 19px;
      color: #555;
}
.store__widget .btn {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
      background-color: var(--primary-color);
      color: white;
}
.store__widget .btn:hover {
      background-color: var(--yellow-color);
      border-color: var(--yellow-color);
}
.filter__content {
      padding: 0 10px;
}
.filter__container {
      margin-bottom: 2rem;
      align-items: center;
      padding: 8px 12px;
      border: 1px solid #ddd;
      border-radius: 5px;
}
.product__content {
      padding: 0 10px;
      text-align: center;
      margin-bottom: 20px;
}
.product__content .product__item {
      display: block;
      background: #fff;
      padding: 20px;
      min-height: 420px;
      position: relative;
      border: 1px solid #ddd;
      border-radius: 15px;
}
.product__content .product__item a {
      color: #222 !important;
      outline: 0 !important;
      text-decoration: none !important;
      transition: all 0.3s ease-in-out !important;
      display: inline !important;
      font-size: 19px !important;
}
.product__content .product__item a:hover {
      color: var(--primary-color);
}
.product__content .product__item .product-status {
      font-size: 14px;
      padding: 5px 15px;
      color: #222933;
      line-height: 18px;
      position: absolute;
      top: 20px;
      left: 20px;
      font-weight: 500;
      border-radius: 50px;
}
.product__content .product__item .product-status.sale {
      background: #666;
      color: #fff;
}
.product__content .product__item .stock-status {
      position: absolute;
      right: 20px;
      font-size: 12px;
      top: 15px;
      font-weight: 400;
      color: #666;
      text-align: right;
}
.product__content .product__item .article-img {
      display: table;
      width: 100%;
      height: 180px;
      margin: auto;
}
.product__content .product__item .article-img img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      transform: scale(1);
      transition: 0.3s ease-in-out;
}
.product__item:hover .article-img img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
}
.product__content .product__item .product-title {
      font-size: 16px;
      line-height: 20px;
      display: block;
      margin-top: 30px !important;
}
.product__item a:hover .product-title {
      color: var(--secondary-color);
}
.product__item .price-title {
      font-size: 17px;
      font-weight: 700;
      color: var(--primary-color);
      display: block;
      line-height: 26px;
      margin-top: 22px;
}
.product__item .btn.rounded-btn {
      background: var(--primary-color);
      color: #fff;
      padding: 5px 20px;
      font-weight: 500;
      display: inline-block;
      margin: 30px 0 0;
      line-height: 28px;
      font-size: 16px;
      border-radius: 0;
}
.product__item .btn.rounded-btn:hover {
      color: #fff !important;
      background: var(--yellow-color);
      border-color: var(--yellow-color);
}
.old-price {
      text-decoration: line-through;
      font-size: 16px;
      color: #f0191e;
      margin-left: 7px;
}
@media (max-width: 1024px) {
      .feature__product-title::before,
      .feature__product-title::after {
            width: 35%;
      }
}
@media (max-width: 992px) {
      .feature__product-title {
            font-size: 18px;
      }
      .store__container label {
            font-size: 14px;
      }
      .store__widget .accordion .accordion-item .accordion-button,
      .accordion .accordion-item .accordion-button.collapsed {
            font-size: 14px;
      }
      .store__widget .accordion-body label.form-check-label {
            font-size: 13px;
      }
      .price-field span {
            font-size: 14px;
      }
      .price-field input {
            height: 45px;
            max-width: 118px;
      }
}
@media (max-width: 768px) {
      .feature__product-title::before,
      .feature__product-title::after {
            width: 30%;
      }
}
