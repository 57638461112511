.mySwiper-products {
      width: 100%;
      height: 300px;
      margin-bottom: 20px;
      overflow: hidden;
}

.mySwiper-products .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      transition: transform 0.3s ease;
      border: 1px solid #ddd;
      border-radius: 5px;
}

.mySwiper-products .swiper-slide img {
      height: 100%;
      object-fit: contain;
      transition: transform 0.3s ease;
}

.mySwiper-products .swiper-slide:hover img {
      transform: scale(1.05);
}

.mySwiper-product-small-image {
      display: flex;
      align-items: center;
      margin-top: 10px;
}

.mySwiper-product-small-image .swiper-slide {
      width: 80px;
      height: 80px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
      transition: border-color 0.3s;
}

.mySwiper-product-small-image .swiper-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.3s;
}

.mySwiper-product-small-image .swiper-slide:hover {
      border-color: var(--primary-color);
}

.mySwiper-product-small-image .swiper-slide.swiper-slide-thumb-active {
      border-color: var(--primary-color);
      opacity: 1;
}
