.dealer__image {
      justify-self: center;
}

.dealer__section h1 {
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 20px;
      color: var(--primary-color);
}

.dealer__section p {
      font-size: 1rem;
      line-height: 1.5;
}
.dealer__form {
      padding-top: 3rem;
      padding-bottom: 3rem;
}

.dealer__form input,
.dealer__form textarea {
      padding: 15px;
}

.dealer__form h2 {
      font-size: var(--font-extraLarge);
      font-weight: 400;
      line-height: 3rem;
      margin-bottom: 20px;
}

.dealer__form .btn {
      margin-top: 10px;
      background-color: var(--primary-color);
      border: 0;
      font-weight: 500;
      transition: background-color 0.4s ease;
      width: 100%;
      color: white;
}

.dealer__form .spinner-border {
      width: 1rem;
      height: 1rem;
      border-width: 1px;
}

.dealer__form .btn:hover {
      background-color: var(--yellow-color);
      border-color: var(--yellow-color);
      color: #222;
}
