.single-blog__img a {
      width: 100%;
}
.blog-box:hover .single-blog__img img {
      transform: scale(1.1);
}
.blog-box:hover .blog-title {
      color: var(--primary-color);
}
.custom-sticky {
      position: sticky;
      top: 100px;
}
.recent-post {
      font-size: 22px;
      margin-bottom: 1rem;
      position: relative;
}

.recent-img a {
      display: inline-block;
      height: 100%;
      width: 100%;
}

.recent-post::before {
      content: "";
      position: absolute;
      height: 25px;
      width: 4px;
      top: 5px;
      left: -33px;
      background-color: var(--primary-color);
}
.single-blog__img img {
      transition: transform 0.3s ease-out;
      object-fit: cover;
      object-position: center;
      height: 284px;
      width: 100%;
}
.blog-box {
      border-radius: 4px;
      overflow: hidden;
}
.single-blog__content {
      border-bottom-right-radius: 4px;
}
.single-blog__img {
      height: 100%;
      border-bottom-left-radius: 4px;
      overflow: hidden;
}
.single-blog__content {
      padding: 20px;
      height: 100%;
      background-color: #f2f2f2;
}
.blog-body .font-extraSmall {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
}
.blog-title {
      color: #222;
      font-size: 22px;
      line-height: 1.2;
      margin-bottom: 5px;
}
.blog-meta span {
      color: var(--gray-color);
}
.widget-container {
      padding: 2rem;
      background-color: #f2f2f2;
      border-radius: 4px;
}

/* chnages */
.widget-custom-change {
      margin-bottom: 20px;
}

.recent-post-categories a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      color: #222;
}
.recent-post-categories a:hover {
      color: var(--primary-color);
}

.recent-post-categories:last-child {
      margin-bottom: 0;
}
