:root {
      --swiper-navigation-size: 26px !important;
      /*
	--swiper-navigation-top-offset: 50%;
	--swiper-navigation-sides-offset: 10px;
	--swiper-navigation-color: var(--swiper-theme-color);
	*/
}
.section-pattern {
      background-image: url("/public/assets/pattern02.png");
      position: relative;
      background-repeat: no-repeat;
      background-position: center left;
      background-size: contain;
      max-height: 20%;
      height: 40rem;
      max-width: 40rem;
      width: 30%;
      margin-top: -5.5rem;
}
.home-section {
      position: relative;
      /* height: 630px; */
      width: 100%;
}
.home-section img,
.home-section video {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
}
.home-section::after {
      /* content: ""; */
      z-index: 55;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3rem;
      background: linear-gradient(
            to top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
      );
}

.home-section::before {
      /* content: ""; */
      z-index: 55;
      position: absolute;
      /* top: 0; */
      left: 0;
      right: 0;
      bottom: -1px;
      height: 0.5rem;
      background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.89) 100%
      );
}
@media only screen and (max-width: 1290px) {
      .custom-box .font-medium {
            /* display: none; */
            font-size: 1.25;
      }
      .custom-box .font-small {
            font-size: 1rem;
      }
}
.scroll-down {
      position: relative;
      /* transform: translateY(-50%); */
      display: flex;
      z-index: 10;
      margin-top: 20px;
      align-items: center;
      justify-content: center;
}

.scroll-down span:nth-child(2) {
      animation-delay: -0.2s;
}
.scroll-down span:nth-child(3) {
      animation-delay: -0.4s;
}
.scroll-down span {
      display: block;
      width: 20px;
      height: 20px;
      border-bottom: 1px solid var(--primary-color);
      border-right: 1px solid var(--primary-color);
      transform: rotate(45deg);
      margin: -10px;
      animation: animate 2s infinite;
}
@keyframes animate {
      0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
      }
      50% {
            opacity: 1;
      }
      100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
      }
}

@media screen and (max-width: 568px) {
      .home-section {
            margin-top: 55px;
      }
}
