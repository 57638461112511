.custom-headline__wrapper {
      width: 100%;
}
.custom-headline span {
      font-size: 17px;
      /* letter-spacing: 0.1em; */
      font-weight: 400;
}
.custom-headline h4 {
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 1.3em;
}

.customSpan {
      position: relative;
}
.main {
      position: relative;
      /* text-transform: uppercase; */
      font-size: 20px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.2rem;
      line-height: 33px;
}
.main span {
      width: 100%;
      border-bottom: 2px solid var(--primary-color);
      height: 2px;
      position: relative;
}

.custom-headline .custom__title {
      min-width: max-content;
}

@media screen and (max-width: 568px) {
      .custom-headline .custom__title {
            min-width: fit-content;
      }

      .custom-headline {
            margin-top: 10px;
      }
}
