@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Outfit:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100..800&display=swap");
* {
	font-family: "Barlow", sans-serif;
}
:root {
	--primary-color: #1c3677;
	--secondary-color: #13528d;
	--bg-color: #e0f3ff;
	--font-base: "Outfit", "sans-serif";
	--text-headline01: #041b34;
	--yellow-color: #f7bf23;
	--gray-color: #4d4d4d;
	--font-medium: 32px;
	--font-large: 2.5rem;
	--font-extraLarge: 30px;
	--font-small: 18px;
	--font-extraSmall: 16px;
	--font-xxl: 5rem;
	--font-xl: 3rem;
	--font-xxs: 14px;
}
.font-xxl {
	font-size: var(--font-xxl);
	font-weight: 700;
}
.font-xl {
	font-size: var(--font-xl);
	font-weight: 600;
}
.font-large {
	font-size: var(--font-large);
}
.font-medium {
	font-size: var(--font-medium);
	font-weight: 600;
}
.font-small {
	font-size: var(--font-small);
	font-weight: 300;
}
.font-extraSmall {
	font-size: var(--font-extraSmall);
}
.font-xxs {
	font-size: var(--font-xxs);
	font-weight: 300;
}
.font-extraLarge {
	font-size: var(--font-extraLarge);
	font-weight: 400;
	line-height: 3rem;
}

a {
	text-decoration: none;

	display: block;
	display: inline-block;
	/* font-family: var(--font-base); */
}

.text {
	letter-spacing: 1.2px;
	font-size: 14px;
	font-weight: 600;
}
.custom-white {
	color: #fff;
}
.custom-span-weight {
	font-weight: 300;
}

h5 {
	font-size: 32px;
	font-weight: 600;
}
h1,
h2,
h3,
h4,
h5 {
	/* font-family: var(--font-base); */
}
p {
	/* font-family: var(--font-base); */
	font-weight: 300;
	font-size: 18px;
}
.sectionPadding {
	padding: 6em 0 6em 0;
}
.sectionMargin {
	margin-top: 5rem;
}
.marginBottom {
	margin-bottom: 3em;
}
strong {
	font-weight: 600;
}
.custom-padding{
	padding-bottom: 3em !important;
}
.customsectionPadding{
	padding: 3em 0;
}