.products__solutions {
      position: relative;
      padding: 3em 0px;
      background-color: #f2f2f2;
      margin-top: 3em;
}

.products__solutions .products__solutions-title h2,
.products__solutions .products__solutions-title h3 {
      color: var(--secondary-color);
      margin-bottom: 16px;
      font-size: 20px;
      letter-spacing: 1px;
}

.products__solutions .products__solutions-title h2 {
      font-size: 40px;
      font-weight: 700;
}

.products__solutions .products__solutions-title h3 {
      font-size: 30px;
      font-weight: 600;
}

.products__solutions .products__solutions-title p {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 32px;
}

.service__tab-wrapper {
      display: flex;
}
.service__tab-wrapper .nav-pills {
      display: flex;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      border-bottom: none;
      width: calc(100% - 728px);
      background: linear-gradient(
            to left,
            rgb(14, 149, 179) 1%,
            rgb(0, 20, 94)
      );
      border-bottom-left-radius: 80px;
}
.nav-pills .nav-link.active,
.nav-pills .nav-link {
      padding: 35px 55px;
}
.nav-pills .nav-link {
      border-radius: 0px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
      background-color: #ffffff38;
      /* border-color: #dee2e6 #dee2e6 #fff; */
      margin-right: -1px;
      color: #fff;
}
.nav-item h3 {
      white-space: nowrap;
      color: #fff;
      border: none;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
}

.tab-pane-card {
      border: none;
      background-color: transparent;
}
.card-body {
      display: block;
      padding: 0;
      position: relative;
}
.card-body .service-content {
      position: relative;
}
.card-body .service-content .contents-wrap {
      max-width: 728px;
      height: 295px;
}
.card .card-body .service-content .contents-wrap img {
      width: 100%;
      height: 100%;
      border-top-right-radius: 80px;
}
.card .card-body .service-content .contents-wrap .contents-bottom {
      height: 100px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      padding: 9px 18px;
      -ms-flex-pack: justify;
      justify-content: space-between;
      background-color: #fff;
      align-items: center;
}
.card .card-body .service-content .contents-wrap .contents-bottom .contents {
      width: calc(100% - 194px);
}
.card .card-body .service-content .contents-wrap .contents-bottom .contents p {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 500;
      height: 77px;
      overflow: hidden;
}
p {
      margin-top: 0;
      margin-bottom: 1rem;
}

@media screen and (max-width: 568px) {
      .products__solutions .products__solutions-title h2 {
            font-size: 28px;
            font-weight: 600;
      }

      .products__solutions .products__solutions-title h3 {
            font-size: 24px;
      }

      .card .card-body .service-content .contents-wrap .contents-bottom {
            height: auto;
      }

      .service__tab-wrapper .nav-pills {
            width: 100%;
      }

      .service__tab-wrapper {
            flex-direction: column;
      }

      .service__tab-wrapper .nav-pills {
            order: 2;
      }

      .card-body .service-content .contents-wrap {
            height: auto;
      }
}
