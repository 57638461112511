.product__detail {
      padding: 30px;
}
.product__detail .row {
      align-items: start;
      justify-content: flex-start;
      text-align: left;
}
.product__image {
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
}
.product__image img {
      display: block;
      width: 100%;
      object-fit: contain;
      height: 450px;
}
.product__detail--title h4 {
      font-size: 28px;
      line-height: 32px;
      color: var(--primary-color);
}
.product__detail--price span {
      font-size: 1.5rem;
      text-transform: uppercase;
      color: #555;
}
.product__detail--quantity {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      gap: 10px;
      margin: 25px 0;
}
.quantity {
      display: flex;
      border: 1px solid #ddd;
      border-radius: 5px;
      overflow: hidden;
}
.quantity button {
      background-color: #f1f1f1;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 41px;
}
.quantity input {
      width: 50px;
      text-align: center;
      border: none;
      outline: none;
      font-size: 16px;
}
.product__detail---delivery {
      width: 75%;
      font-size: 12px;
}
.list-group-flush > .list-group-item:last-child {
      padding-left: 0;
      list-style: none;
}
.list-group-flush > .list-group-item:last-child {
      background-color: #f0f0f0;
      list-style-type: none;
}
.delivery-image {
      margin-left: 5px;
      margin-right: 5px;
}
.heading-5 {
      font-size: 1.25rem;
}
.social-links li {
      display: inline-block;
      padding-right: 10px;
      padding-left: 10px;
}
.social-links li:first-child {
      padding-left: 0;
}
.post-share a {
      color: black;
}
.post-share a:hover {
      color: var(--primary-color);
      transition: all 0.3s;
}
.product__detail #nav-tab {
      border-bottom: 2px solid #ddd;
      margin-bottom: 20px;
}
.product__detail .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: #555;
      padding: 10px 20px;
      font-weight: bold;
      transition: all 0.3s ease;
}
.product__detail .nav-link.active {
      background-color: #fff;
      border-color: #ddd #ddd transparent;
      color: #333;
      box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
}
.product__detail .nav-tabs .nav-link:focus,
.product__detail .nav-tabs .nav-link:hover {
      border-color: transparent;
}
.product__detail .tab-pane p {
      font-size: 18px;
      color: #555;
      margin-top: 0;
}
.product__detail .tab-pane ul li {
      padding-left: 20px;
      font-size: 18px;
      line-height: 2;
}
.similar__product .product__item {
      display: block;
      background: #fff;
      padding: 20px;
      min-height: 420px;
      position: relative;
      border: 1px solid #ddd;
      border-radius: 15px;
      text-align: center;
}
.similar__product .product__item a {
      color: #222 !important;
      outline: 0 !important;
      text-decoration: none !important;
      transition: all 0.3s ease-in-out !important;
      display: inline !important;
      font-size: 19px !important;
}
.similar__product .product__item a:hover {
      color: var(--primary-color);
}
.similar__product .product__item .product-status {
      font-size: 14px;
      padding: 5px 15px;
      color: #222933;
      line-height: 18px;
      position: absolute;
      top: 20px;
      left: 20px;
      font-weight: 500;
      border-radius: 50px;
}
.similar__product .product__item .product-status.sale {
      background: #666;
      color: #fff;
}
.similar__product .product__item .stock-status {
      position: absolute;
      right: 20px;
      font-size: 12px;
      top: 15px;
      font-weight: 400;
      color: #666;
      text-align: right;
}
.similar__product .product__item .article-img {
      display: table;
      margin: 50px auto 20px;
      width: 142px;
      height: 142px;
}
.similar__product .product__item .article-img img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      transform: scale(1);
      transition: 0.3s ease-in-out;
}
.product__item:hover .article-img img {
      transform: scale(1.1);
      transition: 0.3s ease-in-out;
}
.similar__product .product__item .product-title {
      font-size: 16px;
      line-height: 20px;
      display: block;
      margin-top: 30px !important;
}
.product__item a:hover .product-title {
      color: var(--secondary-color);
}
.product__item .price-title {
      font-size: 17px;
      font-weight: 700;
      color: #143b88;
      display: block;
      line-height: 26px;
      margin-top: 22px;
}
.product__item .btn.rounded-btn {
      background: #143b88;
      color: #fff;
      padding: 5px 20px;
      display: inline-block;
      margin: 30px 0 0;
      line-height: 28px;
      font-size: 16px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
}
.product__item .btn.rounded-btn:hover {
      color: #fff !important;
      background: var(--yellow-color);
}
.similar__product .heading-title {
      position: relative;
}
.similar__product .heading-title h2 {
      font-size: 1.75rem;
      font-weight: 700;
      display: inline-block;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
}
@media (max-width: 1024px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
}

/* changes */

.input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      width: 20%;
}

.btn-primary {
      background-color: var(--primary-color);
      border: var(--primary-color);
}

.product-details-card {
      border: none;
}

.nav-link {
      color: var(--primary-color);
}
.nav-link:hover {
      color: var(--primary-color);
}
.description-productdetails {
      line-height: 2;
      text-align: justify;
}
