.home-history {
      padding: 5rem 0;
      padding-bottom: 0px !important;
      /* position: relative; */
}

.history-image {
      text-align: start;
      border-radius: 10px;
}

.history-image img {
      max-width: 100%;
      height: 100%;
      border-radius: 10px;
}

.history-content {
      padding: 60px 20px;
      padding-top: 0rem;
      padding-left: 30px;
      position: relative;
}

.our-story {
      font-size: 1rem;
      color: #6c757d;
      margin-bottom: 0.5rem;
}

.building-better {
      font-size: 22px;
      font-weight: 600;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
}

.history-title {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 1rem;
}

.history-description {
      font-size: 1rem;
      color: #6c757d;
      margin-bottom: 40px;
      text-align: justify;
}

.timeline {
      display: flex;
      align-items: center;
}

.year {
      font-size: 0.875rem;
      margin-right: 10px;
}

.line {
      display: flex;
      align-items: center;
      flex: 1;
      position: relative;
}

.dot {
      width: 10px;
      height: 10px;
      background-color: #6c757d;
      border-radius: 50%;
      margin: 0 5px;
      transition: background-color 0.3s;
}

.dot.active {
      background-color: #007bff;
}

.line::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #6c757d;
      z-index: -1;
}

@media screen and (max-width: 568px) {
      .history-image {
            height: 260px;
      }

      .history-description {
            margin-bottom: 4rem;
      }

      .history-content {
            padding: 20px 0;
      }
}
