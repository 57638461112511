.testomonial-img {
      width: 85px;
}
.testomonial-img img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
}
.single-testomonial {
      display: flex;
      color: #fff;
      text-align: center;
      background-position: center center;
      background-size: cover;
      padding: 10px 25px 30px 25px;
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
}
.single-testomonial:after {
      position: absolute;
      content: "";
      left: 0%;
      bottom: 0%;
      border-radius: 15px;
      width: 100%;
      height: 80%;
      z-index: -1;
      background-color: #294f99;
}
.rating-stars i {
      font-size: 24px;
      color: #f7bf23;
}
.single-testomonial .custom {
      color: #fff;
}
.single-testomonial .custom-es {
      color: #d2d2d2;
}
.testomonial-img {
      border: 4px solid #f7bf23;
      border-radius: 50%;
      height: 77px;
      width: 77px;
}

.testomonial-img img {
      object-fit: cover;
      object-position: top;
}

.content {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
}
